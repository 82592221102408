import './bootstrap'
import 'v-calendar/dist/style.css'
import '../css/app.css'
import 'vue-universal-modal/dist/index.css'

import Unicon from 'vue-unicons'
import {uniBag, uniApps, uniKid, uniStar, uniUsdCircle,uniHistory, uniArchive, uniArrowCircleRight,uniArrowCircleDown,uniRecordAudio,uniGrin,uniMeh,uniSad, uniFrown, uniSmile, uniBars, uniEdit, uniShieldCheck,uniTrash,uniInfoCircle, uniDraggabledots,uniTimesCircle,uniClipboard,uniCircle, uniCheckCircle,uniRedo,uniSchedule,uniDumbbell } from 'vue-unicons/dist/icons'

import VueUniversalModal from 'vue-universal-modal'

import Vue3Editor from "vue3-editor";

import { Quill } from 'vue3-editor';
import VueGtag from 'vue-gtag';


const Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);

import { createApp } from 'vue/dist/vue.esm-bundler';

import router from './router';
import store from './store';

import cryptoMixin from './cryptoMixin'
import dateMixin from './dateMixin'


Unicon.add([uniBag, uniApps, uniStar, uniKid, uniUsdCircle,uniHistory, uniArchive, uniArrowCircleRight,uniArrowCircleDown, uniRecordAudio,uniGrin,uniMeh,uniSad,uniBars, uniFrown,uniSmile, uniEdit,uniTrash,uniDraggabledots,uniInfoCircle, uniTimesCircle, uniClipboard, uniCircle, uniCheckCircle, uniRedo,uniShieldCheck,uniSchedule, uniDumbbell])


createApp({})
  .mixin(cryptoMixin)
  .mixin(dateMixin)
  .use(store)
  .use(Vue3Editor)
  .use(Unicon)
  .use(VueUniversalModal, {
    teleportTarget: '#modals'
  })
  .use(VueGtag, {
    config: { id: 'G-7S3E46TM76' }
  }, router)
  .use(router)
  .mount("#app");