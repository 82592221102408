import _ from 'lodash';
window._ = _;

import 'bootstrap';

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from 'axios';

window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.axios.defaults.withCredentials = true;

window.axios.defaults.baseURL = import.meta.env.APP_API_URL;

/**
window.axios.interceptors.request.use(function (config) {
  //If I one day decide to encrypt all data.
  console.log(config.data)
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

**/

window.axios.interceptors.response.use(function (response) {
    //One day I might add decryption here.  I would add another interceptor for requests to handle encryption.
    return response;
}, function (error) {
    if (typeof error.response !== 'undefined') {
      if (419 === error.response.status) {
         console.log("419 Status Error");
          return error.message;
      } else {
        //Something else you want to do or do nothing

        if(401 == error.response.status) {
          return error.response;
        }

        if(403 == error.response.status) {
            return error.response;
        }

        if(422 == error.response.status) {
            return error.response;
        }

        if(429 == error.response.status) {
            alert("Too Many Requests!");
        }

        if(500 == error.response.status) {
          if(error.response.data.error) {
            alert(error.response.data.error);
          }
        }
      }
    }
});
